import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const ChangePassword = ({ setToken, setType, mobile, token }) => {

    const [oldp, setOldp] = useState();
    const [newp, setNewp] = useState();
    const [cnewp, setCnewp] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    async function changePAssword(e) {
        e.preventDefault();

        setIsLoading(true);

        if (oldp == null || newp == null || cnewp == null) {
            setIsLoading(false);
            alert('All fields must be filled')
            return
        }

        if (newp != cnewp) {
            setIsLoading(false);
            alert('New passwords do not match')
            return
        }

        let formfield = new FormData()

        formfield.append('oldp', oldp)
        formfield.append('newp', newp)
        formfield.append('cnewp', cnewp)

        try {
            const { data } = await axios({
                url: 'https://api.emialuge.com/change_password',
                method: 'POST',
                data: formfield,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
            })

            if (data.status == 'success') {
                setIsLoading(false);
                setOldp('')
                setNewp('')
                setCnewp('')
                alert('Password changed successfully')
                return
            }

            if (data.status == 'failed') {
                setIsLoading(false);
                alert('Unauthenticated')
                return
            }
        } catch (error) {
            setIsLoading(false);
            alert('Something went wrong, please try again later')
            return
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section id="main-content" className={mobile ? "merge-left" : ""}>
                <section className="wrapper">
                    <div className="market-updates">
                        <div className="col-md-12 market-update-gd">
                            <div className="">
                                <div className="panel panel-default">
                                    <div className="panel-heading" style={{ textAlign: "left" }}>
                                        Change Password
                                    </div>
                                    <form onSubmit={changePAssword} style={{
                                        paddingTop: '15px',
                                        paddingRight: '15px',
                                        paddingLeft: '15px',
                                        paddingBottom: '30px'
                                    }}>
                                        <label>Enter Old Password</label>
                                        <input type="text" className="ggg" value={oldp} name="oldp" placeholder="Name" onChange={e => setOldp(e.target.value)} required="" style={{
                                            width: '100%',
                                            padding: '15px 0px 15px 15px',
                                            border: '1px solid #ccc',
                                            outline: 'none',
                                            fontSize: '14px',
                                            color: '#333',
                                            margin: '14px 0px',
                                            backgroundColor: '#fff',
                                            paddingTop: '15px',
                                            paddingBottom: '15px'
                                        }} />

                                        <label>Enter New Password</label>
                                        <input type="text" className="ggg" value={newp} name="price" placeholder="Price" onChange={e => setNewp(e.target.value)} required="" style={{
                                            width: '100%',
                                            padding: '15px 0px 15px 15px',
                                            border: '1px solid #ccc',
                                            outline: 'none',
                                            fontSize: '14px',
                                            color: '#333',
                                            margin: '14px 0px',
                                            backgroundColor: '#fff',
                                            paddingTop: '15px',
                                            paddingBottom: '15px'
                                        }} />

                                        <label>Enter New Password</label>
                                        <input type="text" className="ggg" value={cnewp} name="quantity" placeholder="Quantity" onChange={e => setCnewp(e.target.value)} required="" style={{
                                            width: '100%',
                                            padding: '15px 0px 15px 15px',
                                            border: '1px solid #ccc',
                                            outline: 'none',
                                            fontSize: '14px',
                                            color: '#333',
                                            margin: '14px 0px',
                                            backgroundColor: '#fff',
                                            paddingTop: '15px',
                                            paddingBottom: '15px'
                                        }} />

                                        <input type="submit" value={isLoading ? "SAVING..." : "SAVE"} name="login" disabled={isLoading} />
                                        {isLoading && <span style={{ marginLeft: '10px' }}>Loading...</span>}
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix"> </div>
                    </div>
                </section>
            </section>
        </div>
    );
};

export default ChangePassword;