import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const Expenditure = ({ setToken, setType, mobile, token }) => {

    const [title, setTitle] = useState();
    const [amount, setAmount] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    async function addExpenditure(e) {
        e.preventDefault();

        setIsLoading(true);

        if (title == null || amount == null) {
            setIsLoading(false);
            alert('All fields must be filled')
            return
        }

        let formfield = new FormData()

        formfield.append('title', title)
        formfield.append('amount', amount)

        try {
            const { data } = await axios({
                url: 'https://api.emialuge.com/add_expenditure',
                method: 'POST',
                data: formfield,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
            })

            if (data.status == 'success') {
                setIsLoading(false);
                setTitle(null)
                setAmount(null)
                alert('Expenditure added successfully')
                return
            }

            if (data.status == 'failed') {
                setIsLoading(false);
                alert('The Operation has failed, please check your entry and try again')
                return
            }
        } catch (error) {
            setIsLoading(false);
            alert('Something went wrong, please try again later')
            return
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section id="main-content" className={mobile ? "merge-left" : ""}>
                <section className="wrapper">
                    <div className="market-updates">
                        <div className="col-md-12 market-update-gd">
                            <div className="">
                                <div className="panel panel-default">
                                    <div className="panel-heading" style={{ textAlign: "left" }}>
                                        New Expenditure
                                    </div>
                                    <form onSubmit={addExpenditure} style={{
                                        paddingTop: '15px',
                                        paddingRight: '15px',
                                        paddingLeft: '15px',
                                        paddingBottom: '30px'
                                    }}>
                                        <label>Enter Title</label>
                                        <input type="text" className="ggg" name="name" placeholder="Name" onChange={e => setTitle(e.target.value)} required="" style={{
                                            width: '100%',
                                            padding: '15px 0px 15px 15px',
                                            border: '1px solid #ccc',
                                            outline: 'none',
                                            fontSize: '14px',
                                            color: '#333',
                                            margin: '14px 0px',
                                            backgroundColor: '#fff',
                                            paddingTop: '15px',
                                            paddingBottom: '15px'
                                        }} />

                                        <label>Enter Amount</label>
                                        <input type="number" className="ggg" name="price" placeholder="Price" onChange={e => setAmount(e.target.value)} required="" style={{
                                            width: '100%',
                                            padding: '15px 0px 15px 15px',
                                            border: '1px solid #ccc',
                                            outline: 'none',
                                            fontSize: '14px',
                                            color: '#333',
                                            margin: '14px 0px',
                                            backgroundColor: '#fff',
                                            paddingTop: '15px',
                                            paddingBottom: '15px'
                                        }} />

                                        <input type="submit" value={isLoading ? "SAVING..." : "SAVE"} name="login" disabled={isLoading} />
                                        {isLoading && <span style={{ marginLeft: '10px' }}>Loading...</span>}
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix"> </div>
                    </div>
                </section>
            </section>
        </div>
    );
};

export default Expenditure;