import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

async function loginUser(credentials) {
    try {
        const { data } = await axios({
            url: 'http://localhost:4000/login',
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            data: credentials,
        })
    } catch (error) {

    }
}

// async function loginUser(credentials) {
//     return {token:'123tk'}
// }

const Login = ({ setToken, setType, setGrade }) => {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [isLoading, setLoading] = useState(false)

    const handleSubmit = async e => {
        e.preventDefault();

        setLoading(true)

        // if (username == '' || password == '') {
        //     alert('All fields must be filled')
        // }

        let formfield = new FormData()

        formfield.append('email', username)
        formfield.append('password', password)

        try {
            const { data } = await axios({
                url: 'https://api.emialuge.com/login',
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                data: formfield,
            })

            if (data.status == 'failed') {
                setLoading(false)
                alert('Unauthenticated')
                return
            }

            if (data.status == 'success') {
                setLoading(false)
                setType('login')
                setToken({ token: data.token });
                setGrade(data.level);
                sessionStorage.setItem('grade', JSON.stringify(data.level));
                return
            }

        } catch (error) {
            setLoading(false)
            if (error.response.data.status === 'failed') {
                alert('Unauthenticated')
                return
            }else{
                alert("Something went wrong please try again later");
                return;
            }
        }



        // if (token.status == 'failed') {
        //     setLoading(false)
        //     alert('Unauthenticated')
        //     return
        // }

        // setLoading(false)

        // setType('login')
        // setToken({ token: token.token });
        // setGrade(token.level);
        // sessionStorage.setItem('grade', JSON.stringify(token.level));


    }

    return (
        <div>
            <div className="log-w3">
                {isLoading &&
                    <h1 style={{ textAlign: "center" }}>Loading....</h1>
                }
                {!isLoading &&
                    <div className="w3layouts-main">
                        <h2 className="login-title">Sign In Now</h2>
                        <form onSubmit={handleSubmit}>
                            <input type="text" className="ggg" name="Email" placeholder="E-MAIL" required="" onChange={e => setUserName(e.target.value)} />
                            <input type="password" className="ggg" name="Password" placeholder="PASSWORD" required="" onChange={e => setPassword(e.target.value)} />
                            <div className="clearfix"></div>
                            <input type="submit" value="Sign In" name="login" />
                        </form>
                    </div>
                }
            </div>
        </div>
    );
};

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}

export default Login;