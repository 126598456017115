import { useState } from 'react';

export default function useToken(type) {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken?.token
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {

        if(type == 'logout'){
            sessionStorage.clear();
        }else{
            sessionStorage.setItem('token', JSON.stringify(userToken));
        }
        
        setToken(userToken.token);
    };

    return {
        setToken: saveToken,
        token
    }
}