import React from "react";
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { grad } from "@tensorflow/tfjs";

const Sidebar = ({ mobile, setToken, setType, token, grade }) => {

    const userGrade = sessionStorage.getItem('grade');


    async function logout() {

        setType('logout')
        setToken('')


    }

    return <div>
        <aside>
            <div id="sidebar" className={mobile ? "nav-collapse hide-left-bar" : "nav-collapse"}>
                <div className="leftside-navigation">
                    <ul className="sidebar-menu" id="nav-accordion">



                        {(userGrade == '1' || userGrade == '2') &&
                            <>
                                <li className="sub-menu side-bar-item">
                                    <NavLink to="/">
                                        <i className="fa fa-dashboard"></i>
                                        <span>Dashboard</span>
                                    </NavLink>
                                </li>
                                <li className="sub-menu side-bar-item">
                                    <NavLink to="/add-user">
                                        <i className="fa fa-book"></i>
                                        <span>Add User</span>
                                    </NavLink>
                                </li>
                                <li className="sub-menu side-bar-item">
                                    <NavLink to="/users">
                                        <i className="fa fa-book"></i>
                                        <span>Users</span>
                                    </NavLink>
                                </li>
                                <li className="sub-menu side-bar-item">
                                    <NavLink to="/add-product">
                                        <i className="fa fa-book"></i>
                                        <span>Add Product</span>
                                    </NavLink>
                                </li>
                                <li className="sub-menu side-bar-item">
                                    <NavLink to="/products">
                                        <i className="fa fa-book"></i>
                                        <span>Products</span>
                                    </NavLink>
                                </li>
                                <li className="sub-menu side-bar-item">
                                    <NavLink to="/history">
                                        <i className="fa fa-book"></i>
                                        <span>History</span>
                                    </NavLink>
                                </li>
                                <li className="sub-menu side-bar-item">
                                    <NavLink to="/expenditure">
                                        <i className="fa fa-book"></i>
                                        <span>Expenditure</span>
                                    </NavLink>
                                </li>
                            </>
                        }


                        <li className="sub-menu side-bar-item">
                            <NavLink to="/pos">
                                <i className="fa fa-book"></i>
                                <span>POS</span>
                            </NavLink>
                        </li>
                        <li className="sub-menu side-bar-item">
                            <NavLink to="/change-password">
                                <i className="fa fa-book"></i>
                                <span>Change Password</span>
                            </NavLink>
                        </li>
                        <li className="sub-menu side-bar-item">
                            <a type='button' onClick={logout}>
                                <i className="fa fa-user"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </div>

            </div>
        </aside>
    </div>;
};

export default Sidebar;